import React, { useState, useEffect } from "react"
import { ClientOnly, SvgGradient } from "../common"
import List from "../idealline/List"
import Search from "./Search"
import Location from "./Location"
import FirebaseProvider from "../idealline/Firebase"
import { BrowserRouter } from "react-router-dom"

const Gokart = ({
    geo,
    trackCount = 6,
    hideGoKart = false,
    hideRacingLines = false,
    hideTracks = false,
    tracks
}) => {
    const [city, setCity] = useState(geo?.city || "")
    const [latLng, setLatLng] = useState(
        geo?.lat && { lat: geo.lat, lng: geo.lng }
    )

    const loadLocationFromWorker = async () => {
        const geoDataFromIpResponse = await fetch("https://or.ghe.workers.dev")
        const { latitude, longitude, city } = await geoDataFromIpResponse.json()
        setCity(city)
        setLatLng({ lat: latitude, lng: longitude })
    }

    useEffect(() => {
        if (geo?.lat) return

        const localCity = localStorage.getItem("city")
        const localLatLng = localStorage.getItem("latLng")
        if (localLatLng) {
            setLatLng(JSON.parse(localLatLng))
            if (localCity) setCity(JSON.parse(localCity))
            return
        }

        loadLocationFromWorker()
    }, [])

    useEffect(() => {
        if (geo?.lat || !latLng) return
        localStorage.setItem("latLng", JSON.stringify(latLng))

        if (!city) return
        localStorage.setItem("city", JSON.stringify(city))
    }, [city, latLng])

    let defaultFacets = []
    if (!hideGoKart) defaultFacets.push("gokart")
    if (!hideTracks) defaultFacets.push("racetrack")

    const pageDescription = city ? `Welcome to the ultimate guide for go kart racing tracks and racing circuits in ${city}. Use our search features to find the best tracks and circuits near you!` : `Welcome to the ultimate guide for go kart racing tracks and racing circuits. Use our search features to find the best tracks and circuits near you!`;


    return (
        <>
            <h1>Racing Tracks Near {city ? city : "Me"}</h1>
            <div className="leading-snug my-6 first-letter:uppercase">
                {!hideGoKart && (city ? `Go kart racing tracks in ${city}` : "Go kart racing tracks")}
                {!hideGoKart && !hideTracks && " and "}
                {!hideTracks && (city ? `racing circuits around ` : "racing circuits ")}
                <Location
                    city={city}
                    updateCity={setCity}
                    updateLatLng={setLatLng}
                />
                . Use the map and filters to narrow your results.
            </div>
            <Search
                latLng={latLng}
                defaultFacets={defaultFacets}
                trackCount={trackCount}
                tracks={tracks}
            />
            <div>
                {pageDescription}
            </div>
            {!hideRacingLines && (
                <ClientOnly>
                    <BrowserRouter>
                        <FirebaseProvider>
                            <h1>Latest Racing Lines</h1>
                            <List
                                wrapperClass="post-feed horizontal-feed lines"
                                limit={10}
                            />
                        </FirebaseProvider>
                    </BrowserRouter>
                </ClientOnly>
            )}
             
            <SvgGradient />
        </>
    )
}

export default Gokart
