import React, { useEffect, useRef } from "react"

let autoComplete

const loadScript = (url, callback) => {
    if (window?.google?.maps?.places?.Autocomplete) {
        callback()
        return
    }

    let script = document.createElement("script")
    script.type = "text/javascript"

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (
                script.readyState === "loaded" ||
                script.readyState === "complete"
            ) {
                script.onreadystatechange = null
                callback()
            }
        }
    } else {
        script.onload = () => callback()
    }

    script.src = url
    document.getElementsByTagName("head")[0].appendChild(script)
}

function handleScriptLoad(updateQuery, autoCompleteRef) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current
    )
    autoComplete.setFields(["geometry", "formatted_address"])
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    )
}

async function handlePlaceSelect(updateQuery) {
    const place = autoComplete.getPlace()
    const formatted_address = place.formatted_address
    const lat = place.geometry?.location?.lat()
    const lng = place.geometry?.location?.lng()
    if (formatted_address && lat && lng)
        updateQuery(formatted_address, lat, lng)
}

function SearchLocationInput({ city, updateCity, updateLatLng, setCity }) {
    const autoCompleteRef = useRef(null)

    const setAddress = (formatted_address, lat, lng) => {
        setCity(formatted_address)
        updateCity(formatted_address)
        updateLatLng({
            lat,
            lng,
        })
        if(window.gtag) window.gtag("event", "set location", {
            event_category: "Search",
            event_label: formatted_address,
        })
    }

    useEffect(() => {
        loadScript(
            `https://maps.googleapis.com/maps/api/js?key=${process.env.GATSBY_GMAPS_API_KEY}&libraries=places`,
            () => handleScriptLoad(setAddress, autoCompleteRef)
        )

        setTimeout(() => {
            autoCompleteRef.current.select()
            autoCompleteRef.current.setSelectionRange(0, 9999) //iOS devices
        }, 300)
    }, [])

    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            const firstAutocompleteItem = document.querySelector(
                ".pac-container>.pac-item:first-child"
            )
            if (firstAutocompleteItem) {
                autoCompleteRef.current.dispatchEvent(
                    new KeyboardEvent("keydown", { keyCode: 40, which: 40 })
                )
                autoCompleteRef.current.dispatchEvent(
                    new KeyboardEvent("keydown", { keyCode: 13, which: 13 })
                )
            }
        }
    }

    return (
        <div onKeyPress={onKeyPress}>
            <input
                ref={autoCompleteRef}
                defaultValue={city}
                className="px-1 outline-none ring-0 text-base"
                autoFocus="autofocus"
            />
        </div>
    )
}

export default SearchLocationInput
