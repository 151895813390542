import React, { useEffect, useState, useRef, Suspense } from "react"
import {
    InstantSearch,
    connectSearchBox,
    connectRefinementList,
    connectInfiniteHits,
    CurrentRefinements,
    Configure,
    connectStateResults,
} from "react-instantsearch-dom"
import { Search } from "@styled-icons/evaicons-solid/Search"
import { FilterRight as Filter } from "@styled-icons/bootstrap/FilterRight"
import { useDebouncedCallback } from "use-debounce"
const SearchMap = React.lazy(() => import("./SearchMap"))
import { initTypesenseInstantsearchAdapter } from "./typesenseInstantsearchAdapter"
import { Map } from '@styled-icons/fa-solid/Map'

import TrackCard from "./TrackCard"

function TrackSEO({ tracks, hitsPerPage }) {
    const trackData = tracks || Array.from(Array(hitsPerPage))
    return (
        <TrackCards>
            {trackData.map((track, index) => (
                <TrackCard key={index} {...track} />
            ))}
        </TrackCards>
    )
}

const TrackCards = ({ children }) => (
    <div className="grid divide-y md:divide-y-0 md:grid-cols-2 overflow-auto h-auto md:gap-10">
        {children}
    </div>
)

const CustomSearchBox = ({
    currentRefinement,
    isSearchStalled,
    refine,
    onClose,
}) => {
    const inputSearch = useRef(null)
    const [isFilter, setIsFilter] = useState(false)
    const [value, setValue] = useState(currentRefinement)

    const debouncedOnChange = useDebouncedCallback(
        (value) => refine(value),
        200
    )

    const onChange = (e) => {
        const value = e.target.value
        setValue(value)
        debouncedOnChange(value)
    }

    return (
        <>
            <form
                noValidate
                action=""
                onSubmit={(event) => event.preventDefault()}
                role="search"
                className="flex items-center w-full px-5 shadow-none outline-none ring-0 rounded-none border-b"
            >
                <label
                    htmlFor="search-input"
                    className="text-gray-400 pl-1 pr-3"
                >
                    <Search className="h-6" />
                </label>
                <input
                    ref={inputSearch}
                    id="search-input"
                    type="search"
                    value={value}
                    onChange={onChange}
                    placeholder="Find tracks"
                    autoComplete="off"
                    className="flex-1 h-full w-full py-6 pr-4 bg-transparent border-0 focus:text-gray-900 text-gray-500 placeholder-gray-500 shadow-none outline-none ring-0 focus:ring-0 truncate text-lg leading-normal appearance-none rounded-none transition-colors duration-200 ease-in-out"
                />
                <a
                    href=""
                    onClick={(event) => {
                        event.preventDefault()
                        setIsFilter((isFilter) => !isFilter)
                    }}
                    // css={[
                    //     tw`text-gray-600 mr-2 p-3 flex items-center`,
                    //     isFilter && tw`text-pink-600 bg-pink-100 rounded-full`,
                    // ]}
                >
                    <Filter className="h-5" />
                </a>
                {onClose && (
                    <a
                        href=""
                        onClick={(event) => {
                            event.preventDefault()
                            onClose()
                        }}
                        className="border-l text-gray-600 p-1 pl-5"
                    >
                        Close
                    </a>
                )}
            </form>
            <div>
                <CurrentRefinements />
            </div>
        </>
    )
}
const SearchBox = connectSearchBox(CustomSearchBox)

const CustomRefinementList = ({ items, refine }) => (
    <>
      {items.map(item => (
        <div key={item.label}>
          <button
            className={`bg-gray-50 capitalize px-3 py-1 rounded-full ${item.isRefined ? 'border-2 border-indigo-600 text-indigo-700' : 'border'}`}
            onClick={event => {
              event.preventDefault();
              refine(item.value);
              if(window.gtag) window.gtag("event", "refine search", {
                event_category: "Search",
                event_label: `${JSON.stringify(item.value)}`,
            })
            }}
          >
            {item.label}
          </button>
        </div>
      ))}
    </>
  )
const RefinementList = connectRefinementList(CustomRefinementList)

const CustomInfiniteHits = ({
    hits,
    hasMore,
    refineNext,
    latLng
  }) => {
    hits.forEach((h) => {
        delete h._highlightResult
        delete h._snippetResult
        delete h.__position
    })

    return (
        <>
            <TrackCards>
                {hits.map((hit) => {
                    return (
                        <TrackCard
                            key={hit.id}
                            latLng={latLng}
                            {...hit}
                        ></TrackCard>
                    )
                })}
            </TrackCards>
            <button
                disabled={!hasMore}
                onClick={refineNext}
                className="inline-block text-sm my-3 mx-1 px-4 py-2 bg-gray-200 text-gray-800 rounded-full focus:outline-none disabled:opacity-50"
            >
                Show more
            </button>
        </>
    )
  };
  
const InfiniteHits = connectInfiniteHits(CustomInfiniteHits)

const CustomStateResults = ({ searchResults, isSearchStalled, hitsPerPage }) => {
    const hasResults = searchResults && searchResults.nbHits !== 0
    const nbHits = searchResults && searchResults.nbHits

    if (isSearchStalled)
        return (
            <TrackCards>
                {Array.from(Array(hitsPerPage)).map((_, i) => (
                    <TrackCard key={i}/>
                ))}
            </TrackCards>
        )

    return <div>{isSearchStalled ? `The search is stalled` : ""}</div>
}

const StateResultsPlaceholder = connectStateResults(CustomStateResults)

const MapButton = ({onClick}) => (
        <button
            onClick={onClick}
            className="shadow-lg md:shadow-none inline-flex text-base font-racing items-center space-x-2 my-3 mx-1 px-4 md:px-3 py-2 md:py-1 bg-gray-50 border border-indigo-100 text-indigo-800 rounded-full focus:outline-none disabled:opacity-50"
        >
            <span>Map</span> <Map className="w-4" />
        </button>
)

const SearchComponent = (props) => {
    const { trackCount, defaultFacets } = props
    const [currentPage, setCurrentPage] = useState(1)
    const [loadMap, setLoadMap] = useState(false)
    const [showMap, setShowMap] = useState(false)
    const [searchClient, setSearchClient] = useState(
        initTypesenseInstantsearchAdapter(props.latLng).searchClient
    )
    const [isClient, setIsClient] = useState(false)

    useEffect(() => {
        setIsClient(true)
    }, [])

    useEffect(() => {
        setSearchClient(
            initTypesenseInstantsearchAdapter(props.latLng).searchClient
        )
        setLoadMap(false)
    }, [props.latLng])

    const onShowMap = () => {
        setShowMap(true)
        setLoadMap(true)
        if(window.gtag) window.gtag("event", "show map", {
            event_category: "Search",
        })
    }

    if(!isClient) return <TrackSEO tracks={props.tracks} hitsPerPage={trackCount} />

    return (
        <InstantSearch
            searchClient={searchClient}
            indexName="tracks"
            {...props}
        >
            <Configure hitsPerPage={trackCount} />
            <div className="relative">
                <header className="h-12 flex items-center sticky top-20 z-10 bg-gray-50/20 backdrop-blur-sm overflow-y-hidden overflow-x-auto">
                    {/* <SearchBox
                    onClose={onClose}
                    defaultRefinement={customState?.query}
                /> */}
                    <div className="flex items-center space-x-2 text-base">
                        <RefinementList
                            attribute="type"
                            facetOrdering={false}
                            defaultRefinement={defaultFacets}
                        />
                         <RefinementList
                            attribute="subtype"
                        />
                        <a
                            href="/suggest-track"
                            rel="noopener noreferrer"
                            className={`text-base whitespace-nowrap px-3 py-1 hover:text-indigo-500`}
                        >
                            Suggest an edit
                        </a>
                    </div>
                    {props.latLng && (
                        <div className="hidden md:block md:ml-auto">
                            <MapButton onClick={onShowMap} />
                        </div>
                    )}
                </header>
                {props.latLng && (
                    <div className="block md:hidden fixed bottom-4 z-10 left-1/2 transform -translate-x-1/2">
                        <MapButton onClick={onShowMap} />
                    </div>
                )}
                <StateResultsPlaceholder hitsPerPage={trackCount} />
                <InfiniteHits latLng={props.latLng} showPrevious />
            </div>
            {loadMap && (
                <Suspense fallback={<></>}>
                    <div className={showMap ? "block" : "hidden"}>
                        <SearchMap
                            latLng={props.latLng}
                            setShowMap={setShowMap}
                        />
                    </div>
                </Suspense>
            )}
        </InstantSearch>
    )
}

export default SearchComponent
