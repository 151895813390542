import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter"

export const initTypesenseInstantsearchAdapter = (latLng) => new TypesenseInstantSearchAdapter({
    server: {
        apiKey: process.env.GATSBY_TYPESENSE_READ_API,
        nodes: [
            {
                host: process.env.GATSBY_TYPESENSE_HOST,
                port: process.env.GATSBY_TYPESENSE_PORT,
                protocol: process.env.GATSBY_TYPESENSE_PROTOCOL,
            },
        ],
        numRetries: 3,
        connectionTimeoutSeconds: 120,
        logLevel: "debug",
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
        queryBy: "name,country",
        sortBy: latLng && `location(${latLng.lat}, ${latLng.lng}):asc`,
    },
    geoLocationField: "location",
})
