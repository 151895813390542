import React, { useState, useRef, useEffect, Suspense } from "react"
import { EditOutline as Edit } from "@styled-icons/evaicons-outline/EditOutline"
import LocationInput from "./LocationInput"
// const LocationInput = React.lazy(() => import("./LocationInput"))

const Location = (props) => {
    const { city: cityName } = props
    const [city, setCity] = useState(cityName)
    const [showLocationInput, setShowLocationInput] = useState(false)
    const onLocationChange = (e) => {
        setShowLocationInput(true)
    }

    useEffect(() => {
        if (cityName) {
            setCity(cityName)
        }
    }, [cityName])

    return (
        <>
            <button
                className="relative inline-flex h-7 w-40 items-center border-b-2 border-indigo-200"
                onClick={onLocationChange}
            >
                <>
                    <span
                        data-cityname
                        className="line-clamp-1 h-5 w-full empty:mb-1 empty:bg-gray-200 empty:animate-pulse"
                    >
                        {city}
                    </span>
                    <Edit className="shrink-0 pl-1 h-5" />
                </>
                <div className="absolute overflow-hidden">
                {showLocationInput && (
                    // <Suspense fallback={<></>}>
                        <LocationInput
                            {...props}
                            city={city}
                            setCity={(city) => {
                                setCity(city)
                                setShowLocationInput(false)
                            }}
                        />
                    // </Suspense>
                )}
                </div>
            </button>
        </>
    )
}
export default Location
